.mtm-tabulator-table .mtm-tabulator.tabulator{
    border-radius: 5px 5px 0 0;
}

.mtm-tabulator-table-round .mtm-tabulator.tabulator{
    border-radius: 5px;
}

.mtm-tabulator-table-round.shadow{
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    background-color: #fff;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: 7px;
    box-sizing: content-box;
    border: 2px solid var(--theme-color-v2-c0-rgb);
}

.mtm-tabulator-table.shadow{
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    background-color: #fff;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: 7px;
    box-sizing: content-box;
    border: 2px solid var(--theme-color-v2-c0-rgb);
}

.mtm-tabulator-table .tabulator {
    border-radius: 0 0 5px 5px;
}

.mtm-tabulator.tabulator {
    /* background-color: rgba(var(--theme-color-v2-c1),0.7) !important; */
    background-color: white !important; 
}

.mtm-tabulator.tabulator .tabulator-header {
    border-bottom: 1px solid transparent !important;
    background-color: var(--theme-color-v2-c1-rgb);
    color: #fff;
} 

.mtm-tabulator.tabulator .tabulator-header .tabulator-col{
    background-color: var(--theme-color-v2-c1-rgb);
}

.mtm-tabulator.tabulator .tabulator-row.tabulator-selectable:hover {
    background-color: rgba(var(--theme-color-v2-c1),0.8);
    
}

.mtm-tabulator-table .mtm-tabulator i.title {
    color : var(--theme-color-v2-c1-rgb);
}

.mtm-tabulator-table-round .mtm-tabulator i.title {
    color : var(--theme-color-v2-c1-rgb);
}

.tabulator-placeholder-contents {
    color : white !important;
}

.tabulator .tabulator-tableholder {
    background-color: rgba(var(--theme-color-v2-c0),0.2);
}

.mtm-tabulator i.menu-icon {
    color: var(--theme-color-v2-c0-rgb);
}

.mtm-tabulator .tabulator-cell.menu-select i.menu-icon {
    color: var(--theme-color-v2-white-rgb);
}