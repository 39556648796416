.mtm-study-answer-choice-number {
    box-sizing: border-box;
    width:36px;
    height:36px;
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;

    border-radius: 100%;
    /* border-color: rgb(139, 163, 182); */
    border : 2px solid rgb(154, 170, 183);

    color: rgb(154, 170, 183);
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.mtm-study-answer-choice-number.inactive {
    cursor: default;
}

.mtm-study-answer-choice-number.active {
    font-weight: 600;
    /* color : var(--theme-color-main); */
    color :var(--theme-color-v2-c0-rgb);
    /* border : 3px solid var(--theme-color-dark);  */
    border : 3px solid var(--theme-color-v2-c0-rgb); 
}