.mtm-player-testum-progress-step {  
    box-sizing: border-box;
    background-color: #fff;  
    color: #999;  
    border-radius: 50%;  
    height: 30px;  
    width: 30px;  
    display: flex;  
    align-items: center;  
    justify-content: center;  
    border: 3px solid #e0e0e0;  
    transition: 0.4s ease;  
    z-index: 2;
}  

.mtm-player-testum-progress-step.active {  
    /* border-color: var(--theme-color-dark); */
    color : var(--theme-color-v2-c1-rgb);
    border-color: var(--theme-color-v2-c1-rgb);
}  

.mtm-player-testum-progress-step.current {
    background-color: yellow;
}

.mtm-player-testum-progress-bar {
    height : 30px;
    width : 60px;
}

.mtm-player-testum-progress-bar-0 {  
    background-color: #e0e0e0 ;
    position: absolute;  
    top: 50%;  
    left: 0;  
    transform: translateY(-50%);  
    height: 4px;  
    width: 100%;  
    z-index: 0;  
    transition: 0.4s ease;  
}  

.mtm-player-testum-progress-bar-1 {  
    /* background-color: var(--theme-color-dark); */
    background-color: var(--theme-color-v2-c1-rgb);
    position: absolute;  
    top: 50%;  
    left: 0;  
    transform: translateY(-50%);  
    height: 4px;  
    width: 0%;  
    z-index: 1;  
    transition: 0.4s ease;  
}  

.mtm-player-testum-progress .swiper-container {
    /* background-color: var(--theme-color-deeplight); */
    /* background-color: rgba(var(--theme-color-v2-c1),0.2); */
    background: linear-gradient(to right, rgba(var(--theme-color-v2-c1),0), rgba(var(--theme-color-v2-c1),0.5) 50%, rgba(var(--theme-color-v2-c1),0));

}
