/* .card {
    font-weight: 400;
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
} */


.mtm-testum-card-normal{
    box-sizing: border-box;
    
    /* width : var(--question-card-width); */

    font-weight: 400;
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    /* 제출/채점 버튼과 답안 넣는 곳이 겹치는 현상 없애기  */
    margin-bottom : 100px;
}


@media (max-width: 460px) { 
    /* .mtm-testum-card-normal{width : 350px;} */
    .mtm-testum-card-normal{width : 95%;}
} 

@media (max-width: 320px) { 
    /* .mtm-testum-card-normal{width : 280px;} */
    .mtm-testum-card-normal{width : 100%;}
} 

@media (min-width: 550px) { 
    .mtm-testum-card-normal{width : 460px;}
} 

.mtm-study-question-number-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
    /* background-color:transparent;border:2px solid  rgb(243,174,34) ; */
    background-color:transparent;border:2px solid   var(--theme-color-v2-c0-rgb); ;
}

.mtm-study-question-number{
    font-weight: 600;
    /* color : rgb(243,174,34) ; */
    color :  var(--theme-color-v2-c0-rgb);
}
