
  @font-face {
   font-family: 'yg-jalnan';
   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff'); font-weight:normal; font-style:normal;
   }

   @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    }

  @font-face {
    font-family: 'BMJUA';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
 
.post_page{
  font-family: 'Noto Sans KR';
}

 body{
  font-family: 'Noto Sans KR';
  font-weight: normal;
  font-style: normal;
 }

 /* .bold{
  font-family: "BMJUA";
  font-weight: normal;
  font-style: normal;
 } */

/* 
// Rounded Bootstrap Buttons
// https://codepen.io/davidelvar/pen/dYMgrR 
*/

.btn-rounded {
  border-radius : 40px;
}

div.class-mtv-choice-number {
  box-sizing: border-box;
  width:36px;
  height:36px;
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;

  border-radius: 100%;
  /* border-color: rgb(139, 163, 182); */
  border : 2px solid rgb(154, 170, 183);

  color: rgb(154, 170, 183);
  -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

div.class-mtv-choice-number.inactive {
  cursor: default;
}

div.class-mtv-choice-number.active {
  /* border-radius: 50%; */
  /* border-color: rgb(34, 154, 252); */
  /* color: rgb(24, 10, 229); */
  color : rgb(34, 154, 252);
  border : 2px solid rgb(34, 154, 252);
}

.btn-fixed{
  -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  border: transparent;
  outline: 0px;
  user-select: none;
}

.btn-fixed:focus{
  border: transparent !important;
  outline: 0px;
  user-select: none;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2) {
  font-size : 0.5rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  font-size : 0.33rem;
} */

/* div .container-fluid {
  font-size : 0.33rem !important;
} */

/* @media (-webkit-min-device-pixel-ratio: 2) { 
  font-size : 0.5rem;
}

@media (-webkit-min-device-pixel-ratio: 3) { 
  font-size : 0.34rem;
} */


.ytp-pause-overlay-controls-hidden .ytp-pause-overlay{
  display: none !important;
}
div.ytp-pause-overlay.ytp-scroll-min {
  display: none !important;
}

.ytp-scroll-min.ytp-pause-overlay{
  display: none !important;
}

.sunda-inline{
  width:20px;
  height:20px;
}

figure.graphic{
  margin-left: auto;
  margin-right: auto;
}

.graphic-style-align-left,
.graphic-style-align-right {
  clear: none;
}

.graphic-style-align-left {
  float:left;
}

.graphic-style-align-right {
  float:right;
}

.graphic-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.graphic-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

.graphic-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

.btn-icon {
  padding : 0rem 0.7rem
}

figure.graphic img {
  max-width:100%;
  display: block;
  margin: 0 auto;
}


figure.table td,figure.table table th {    
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px;
  /* border: 1px solid #bfbfbf; */
 
  /* width: 100%; */
}

figure.table table {
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double #b3b3b3;
}

.table td, .table th {
  padding: 0.4rem;
  vertical-align: top;
  /* border-top: 1px solid #dee2e6; */
}

#swal2-html-container{
  overflow:visible;
}

.swal2-popup{
  width: 50em;
}

.mtm-testum-card-normal-normal p{
  text-align: start;
}

.mtm-testum-card-normal-number-area{
  text-align: start;
}

#id-mtm-question-swiper-2 .swiper-autoheight{
  height: 500px !important; 
}


/* .course-swiper .swiper-slide-active + .swiper-slide,
.course-swiper .swiper-slide-active + .swiper-slide + .swiper-slide,
.course-swiper .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {opacity:1} */

/* .text-2xl, .text-3xl, .text-4xl {
  font-family: 'yg-jalnan';
 } */

 @keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}

/* 과목리스트 */
section {
  display: none;
  padding: 20px;
  width: 100%;
  height: 500px;
  border: 2px solid black;
  border-radius: 0 30px 30px 30px;
  position: relative;
  overflow: hidden;
}

.course-swiper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

/* .course-swiper {overflow: hidden;} */
.course-swiper .swiper-slide {
  opacity:0; 
  transition:opacity 0.1s;
}
.course-swiper .swiper-slide-active {opacity: 1;}
/* .course-swiper .swiper-slide-next {opacity: 1;} */
.swiper-button-prev {
  transform:translateX(-200%);
}
.swiper-button-next {
  transform:translateX(200%);
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #fff;
  opacity: 0.5;
  padding: 15px 5px;
  border-radius: 20px;
  color: black !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: gray;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 12L14 6V18L8 12Z"></path></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-next {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M16 12L10 18V6L16 12Z"></path></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-pagination {
  transform:translateY(100%);
}

.swiper-pagination-bullet-active {
  background: black;
  
}

input {
  display: none;
}

label {
  display: inline-block;
  position: relative;
  margin: 0 0 -4px;
  width: 100px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 20px;
  color:#b3b3b3;
  /* border: 1px solid gray; */
  border-bottom: none;
}

@media (max-width:1280px) {
  section {
    display: none;
    padding: 20px;
    width: 100%;
    height: 400px;
    border: 2px solid black;
    border-radius: 0 30px 30px 30px;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width:768px) {
  section {
    display: none;
    padding: 20px;
    width: 100%;
    height: 350px;
    border: 2px solid black;
    border-radius: 0 30px 30px 30px;
    position: relative;
    overflow: hidden;
  }

  label {
    display: inline-block;
    position: relative;
    /* margin: 0 0 -4px; */
    width: 60px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    color:#b3b3b3;
    /* border: 1px solid gray; */
    border-bottom: none;
  }
}

@media (max-width:640px) {
  section {
    display: none;
    padding: 10px;
    width: 100%;
    height: 280px;
    border: 2px solid black;
    border-radius: 0 30px 30px 30px;
    position: relative;
    overflow: hidden;
  }
}

label:hover {
  color:black;
  cursor: pointer;
}

input:checked + label {
    color:white;
    border: 2px solid black;
    /* border-bottom: 3px solid white; */
    z-index: 10;
}

input#tab1:checked + label {
  background-color: #692498;
}
input#tab2:checked + label {
  background-color: #3a4ca8;
}
input#tab3:checked + label {
  background-color: #568a35;
}
input#tab4:checked + label {
  background-color: #fd6f22;
}
input#tab5:checked + label {
  background-color: #12887a;
}

#tab1:checked ~ #content1 {
  display: block;
}

#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  border-radius: 30px;
  display: block;
}



.one-page {
  height:100vh;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* 한페이지씩 스크롤 */
@media (max-width:1024px){
  .one-page {
    width: 100%;
    height: 100%;
  }
}

p {
  word-break: keep-all;
}